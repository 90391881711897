import React, { useMemo } from 'react';

import HorizontalScrollIndicators from 'components/uiLibrary/HorizontalScrollIndicators';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import SectionBlock from 'components/Globals/SectionBlock';
import { COMPONENTS } from 'components/Globals/Analytics/constants';

import { CONTACT_TYPE, TP, ENTITY_MAIN_TABS } from 'constants/index';

import { useBuildContacts } from 'utils/contacts';
import usePageContext from 'utils/hooks/usePageContext';

import { useTranslation } from 'src/i18n';

import classes from './EntitySocialLinks.module.scss';

const ContactItem = ({ contact, linkProps, trackingData }) => (
  <LinkButton
    variant="text"
    isLink
    {...(contact?.contactType?.slug === CONTACT_TYPE.SNS && { href: contact?.value, external: true })}
    {...(contact?.contactType?.slug !== CONTACT_TYPE.SNS && { ...linkProps })}
    styles={{
      root: classes.inline,
    }}
    trackingData={trackingData}
  >
    <SpriteIcon icon={contact?.name} size={32} hover />
  </LinkButton>
);

const InlineScrollableView = ({ entity, entityType, contacts, trackingData, showGeneralContact = false }) => {
  const { navigate } = usePageContext();
  const snsContacts = contacts[CONTACT_TYPE.SNS];
  const generalContacts = useMemo(() => {
    const contactTypesToInclude = [CONTACT_TYPE.PHONE, CONTACT_TYPE.EMAIL, CONTACT_TYPE.WEBSITE];
    const uniqueContactsByType = showGeneralContact
      ? Object.entries(contacts)
          .filter(([contactType]) => contactTypesToInclude.includes(contactType))
          .flatMap(([, contactList]) => contactList)
          .reduce((acc, contact) => {
            if (!acc.some(existingContact => existingContact.name === contact.name)) {
              acc.push(contact);
            }
            return acc;
          }, [])
      : [];

    return uniqueContactsByType;
  }, [contacts, showGeneralContact]);
  const contactTabLinkProps = navigate.getLinkProps({
    entityType,
    entity,
    path: ENTITY_MAIN_TABS.CONTACT,
    isPro: false,
  });

  return (
    <>
      <HorizontalScrollIndicators
        triggerSize={0}
        isArrowsEnabled={false}
        styles={{ leftIndicator: classes.scrollLeftIndicator, rightIndicator: classes.scrollRightIndicator }}
      >
        <div className={classes.contactList}>
          {generalContacts?.map((contact, index) => (
            <ContactItem
              key={`${contact?.contactType?.slug}-${index}`}
              contact={contact}
              trackingData={{
                ...trackingData,
                component: COMPONENTS.CONTACT_CTA,
                meta: {
                  id: contact?.name,
                },
              }}
              linkProps={contactTabLinkProps}
            />
          ))}
          {generalContacts?.length > 0 && <div className={classes.divider} />}
          {snsContacts?.map((contact, index) => (
            <ContactItem
              key={`${contact?.contactType?.slug}-${index}`}
              contact={contact}
              trackingData={{
                ...trackingData,
                component: COMPONENTS.SOCIAL_MEDIA_CTA,
                meta: {
                  socialMedia: contact?.name,
                  id: contact?.name,
                },
              }}
            />
          ))}
        </div>
      </HorizontalScrollIndicators>
    </>
  );
};

const EntitySocialLinks = ({
  entity,
  entityType,
  inline,
  sectionProps = {},
  isEditMode,
  styles,
  trackingData,
  showGeneralContact = false,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { generalContacts } = useBuildContacts({ contacts: entity?.contacts });

  if (!generalContacts[CONTACT_TYPE.SNS]?.length || !Object.keys(generalContacts)?.length) {
    return null;
  }

  if (inline) {
    return isEditMode ? (
      <SectionBlock title={t(`${TP}.FN_TAB_DIGITAL_PRESENCE`)} styles={styles} {...sectionProps}>
        <InlineScrollableView contacts={generalContacts} trackingData={trackingData} />
      </SectionBlock>
    ) : (
      <InlineScrollableView
        entity={entity}
        entityType={entityType}
        contacts={generalContacts}
        trackingData={trackingData}
        showGeneralContact={showGeneralContact}
      />
    );
  }

  return (
    <div>
      <div className={classes.socialLinksHeader}>
        <SectionBlock
          title={t(`${TP}.FN_TAB_DIGITAL_PRESENCE`)}
          styles={{
            title: classes.socialLinksHeaderTitle,
            header: classes.socialLinksSectionHeader,
            leftBoxIcon: classes.leftBoxIcon,
          }}
          {...sectionProps}
          seeAllTrackingData={{
            ...trackingData,
            component: COMPONENTS.SEE_ALL_CTA,
          }}
        />
      </div>
      <div className={classes.socialLinksContent}>
        {Object.keys(generalContacts)?.map(contactType => {
          const contactsList = generalContacts[contactType];

          return contactsList?.map(
            (contactItem, index) =>
              [CONTACT_TYPE.SNS, CONTACT_TYPE.OTHER].includes(contactType) && (
                <LinkButton
                  key={`${contactType}-${index}`}
                  variant="text"
                  isLink
                  external
                  href={contactItem?.value}
                  styles={{
                    root: classes.socialLink,
                  }}
                  disableUnderline
                  trackingData={{
                    ...trackingData,
                    component: COMPONENTS.SOCIAL_MEDIA_CTA,
                    meta: {
                      socialMedia: contactItem?.name,
                    },
                  }}
                >
                  <div className={classes.socialLinkInfo}>
                    <SpriteIcon
                      icon={contactType === CONTACT_TYPE.SNS ? contactItem?.name : 'link'}
                      size={24}
                      className={classes.socialIcon}
                    />
                    <SpriteIcon
                      icon={contactType === CONTACT_TYPE.SNS ? `${contactItem?.name}:hover` : 'link:hover'}
                      size={24}
                      className={classes.socialHoverIcon}
                    />
                    <Typography size={14} weight="medium" variant="p" className={classes.linkName}>
                      {contactItem?.name}
                    </Typography>
                  </div>
                  <SpriteIcon className={classes.openNewIcon} icon="open_in_new" size={18} />
                </LinkButton>
              ),
          );
        })}
      </div>
    </div>
  );
};

export default EntitySocialLinks;
