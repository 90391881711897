import React, { useCallback, useMemo } from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';

import { TP, AGENCY_AGREEMENT_TYPES } from 'constants/index';

import { useTranslation } from 'src/i18n';

import classes from './RepresentationDetails.module.scss';

const AGREEMENT_GROUP_KEYS = {
  GENERAL_MANAGER: 'general_manager',
  OPERA_EXCLUSIVE: 'opera_exclusive',
  OPERA_NON_EXCLUSIVE: 'opera_non_exclusive',
  CONCERT_EXCLUSIVE: 'concert_exclusive',
  CONCERT_NON_EXCLUSIVE: 'concert_non_exclusive',
};

const AGREEMENT_GROUPS = [
  {
    key: AGREEMENT_GROUP_KEYS.GENERAL_MANAGER,
  },
  {
    key: AGREEMENT_GROUP_KEYS.OPERA_EXCLUSIVE,
    exclusive: true,
  },
  {
    key: AGREEMENT_GROUP_KEYS.OPERA_NON_EXCLUSIVE,
    exclusive: false,
  },
  {
    key: AGREEMENT_GROUP_KEYS.CONCERT_EXCLUSIVE,
    exclusive: true,
  },
  {
    key: AGREEMENT_GROUP_KEYS.CONCERT_NON_EXCLUSIVE,
    exclusive: false,
  },
];

const TerritoryList = ({ label, countries }) => {
  if (!countries?.length) {
    return null;
  }

  return (
    <>
      <Typography size={12} color="secondary" className={classes.territory_type}>
        {label}
      </Typography>
      {countries.map(country => (
        <Typography size={12} key={country?.id} className={classes.territory_country}>
          {country?.name}
        </Typography>
      ))}
    </>
  );
};

const RepresentationTerritories = ({ label, exclusive, includedCountries, excludedCountries, displayMode }) => {
  const { t } = useTranslation('NS_AGENCY');

  return (
    <div
      className={classnames(classes.representationTerritories, {
        [classes.representationTerritories_displayMode]: !!displayMode,
      })}
    >
      <div>
        <Typography size={12} textTransform="uppercase" color="secondary">
          {label} {exclusive ? t(`${TP}.m_TERRITORIES_E`) : t(`${TP}.m_TERRITORIES_NE`)}:
        </Typography>
      </div>
      <div className={classes.representationTerritories_group}>
        <TerritoryList label={t(`${TP}.REPRESENTATION_INCLUDE`)} countries={includedCountries} />
        <TerritoryList label={t(`${TP}.REPRESENTATION_EXCLUDE`)} countries={excludedCountries} />
      </div>
    </div>
  );
};

const RepresentationDetails = ({ agreements, hideGM = false, displayMode = false }) => {
  const { t } = useTranslation('NS_AGENCY');
  const getAgreementGroupKey = useCallback(
    agreement => {
      const { agreementType, exclusive } = agreement;

      if (agreementType === AGENCY_AGREEMENT_TYPES.GENERAL_MANAGER && !hideGM) {
        return AGREEMENT_GROUP_KEYS.GENERAL_MANAGER;
      }

      if (agreementType === AGENCY_AGREEMENT_TYPES.OPERA) {
        return exclusive ? AGREEMENT_GROUP_KEYS.OPERA_EXCLUSIVE : AGREEMENT_GROUP_KEYS.OPERA_NON_EXCLUSIVE;
      }

      if (agreementType === AGENCY_AGREEMENT_TYPES.CONCERT) {
        return exclusive ? AGREEMENT_GROUP_KEYS.CONCERT_EXCLUSIVE : AGREEMENT_GROUP_KEYS.CONCERT_NON_EXCLUSIVE;
      }

      return null;
    },
    [hideGM],
  );

  const groupedAgreements = useMemo(() => {
    if (!agreements) {
      return [];
    }

    return agreements.reduce((acc, agreement) => {
      const agreementGroupKey = getAgreementGroupKey(agreement);

      if (!agreementGroupKey) {
        return acc;
      }

      if (!acc[agreementGroupKey]) {
        acc[agreementGroupKey] = [];
      }

      acc[agreementGroupKey].push(agreement);

      return acc;
    }, {});
  }, [getAgreementGroupKey, agreements]);

  return (
    <div>
      {AGREEMENT_GROUPS.map(group =>
        groupedAgreements[group.key]?.map(groupAgreement => {
          if (group.key === AGREEMENT_GROUP_KEYS.GENERAL_MANAGER && !hideGM) {
            return (
              <Typography size={12} key={group.key} weight="bold" textTransform="uppercase">
                {t(`${TP}.m_EDIT_ROSTER_LIST_GENERALMANAGER`)}
              </Typography>
            );
          }

          return (
            <RepresentationTerritories
              key={group.key}
              label={groupAgreement?.agreementType}
              includedCountries={groupAgreement.includedCountries}
              excludedCountries={groupAgreement.excludedCountries}
              displayMode={displayMode}
            />
          );
        }),
      )}
    </div>
  );
};

export default RepresentationDetails;
